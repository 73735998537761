<template>
<div>   
    <!-- Buttons -->
    <crud-button :onClickList="fetchDBKullanicis" :onClickAddNew="addNew" />
    <!-- Filters -->
    <DBKullaniciFilters /> 
    
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('tane göster') }}</label>
          </b-col>
        </b-row>
        </div>
        <b-table
            ref="dbKullanicisTable"
            class="position-relative"
            :items="getDBKullanicis"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Hiçbir eşleşen kayıt bulunamadı"
            :sort-desc.sync="isSortDirDesc">

        <template #cell(sAdi)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.sAdi }}</span>
          </div>
        </template>
        
        <template #cell(sKullaniciAdi)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sKullaniciAdi }}</span>
          </div>
        </template>

        <template #cell(sParola)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sParola }}</span>
          </div>
        </template>
        
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="data.item.isActive"
              class="custom-control-success"
              name="check-button"
              @change="deactivate(data.item.id)"
              >
              {{ $t('Aktif/Pasif') }}
            </b-form-checkbox>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Update -->
          <b-button size="sm" variant="primary" @click="update(data.item)">
            {{ $t('Detay') }}
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ dataMeta.of }} öğeden {{ dataMeta.from }} - {{ dataMeta.to }} arası gösteriliyor</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="toplamDBKullanici"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <SaveDBKullanici v-if="modalObject.showModal" :modalObject="modalObject" />
    </b-card>
</div>

</template>

<script>
// Alert
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import useDBKullanici from './useDBKullanici'
import DBKullaniciFilters from './DBKullaniciFilters.vue';
import SaveDBKullanici from './SaveDBKullanici.vue';

export default {
  components: {
    DBKullaniciFilters,
    SaveDBKullanici,
  },
  data() {
    return {
      modalObject: {
        item: {},
        showModal: false,
        onSuccess: this.fetchKullanicis,
      },
    }
  },
  methods: {
    update(item) {
      Object.assign(this.modalObject.item, item);
      this.modalObject.showModal = true;
    },
    addNew() {
      this.modalObject.item = { kullaniciTuru_Id: 2 };
      this.modalObject.showModal = true;
    },
    deactivate(id) {
      this.$store.dispatch('dbKullaniciCustomer/toggleActiveDBKullanici', id)
      .then(response => {
        if (response.statusCode === 200) {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: response.message },
          });
        } else {
          AlertService.error(this, response.message);
        }
      })
    },
    fetchKullanicis() {
      this.fetchDBKullanicis();
    },
  },
  setup() {
    const {
        fetchDBKullanicis,
        getDBKullanicis,
        tableColumns,
        perPage,
        currentPage,
        toplamDBKullanici,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        dbKullanicisTable,
    } = useDBKullanici()
  
    return {
        fetchDBKullanicis,
        getDBKullanicis,
        tableColumns,
        perPage,
        currentPage,
        toplamDBKullanici,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        dbKullanicisTable,
    }
  },
};
</script>

<style>
</style>
