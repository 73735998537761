<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="dbKullaniciYetkiSelect"
    :options="dbKullaniciYetkiList"
    class="grup-filter-select"
    :reduce="val => val.value"
    @input="(val) => $emit('update:dbKullaniciYetkiSelect', val)" 
    :disabled="isDisabled"
    placeholder="Yetki Seç" />
</template>

<script>
export default {
  props: {
    dbKullaniciYetkiSelect: {
      type: [Number, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dbKullaniciYetkiList() {
      return this.$store.getters['dbKullaniciYetkiYonetim/getDbKullaniciYetkis'].map(item => {
        item.label = item.sYetkiAdi;
        item.value = item.id;
        return item;
      })
    },
  },
  methods: {
    fetchDbKullaniciYetkis() {
      this.$store.dispatch('dbKullaniciYetkiYonetim/fetchDbKullaniciYetkis')
    },
  },
  created() {
    this.fetchDbKullaniciYetkis();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
